<template>
  <div>
    <Header /> 
    <main>
      <div class="container">
      <form  @submit="formData" method="post">
      <div class="row">
        <div v-if="output" class="col-md-12">
          <div class="alert" :class="success ? 'alert-success' : 'alert-warning'" role="alert">{{ output }}</div>
        </div>

        <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.companyName" placeholder="Company Name" />
        </div>
 
       <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.name" placeholder="Name" />
        </div>
  
        <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.phone" placeholder="Phone" />
        </div>

        <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.email" placeholder="Email" />
        </div>

        <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.promoCode" placeholder="Promo Code" />
        </div>

        <div class="col-md-4 form-group">
          <input type="text"  class="form-control" v-model="formFields.location" placeholder="Location" />
        </div>
 
        <div class="col-md-4">
          <button type="submit" class="btn btn-info">Submit</button>
        </div>
      </div>
    
    </form>
      </div>
      <Footer />
    </main>
  </div>
</template>
<script>
import Header from '@/components/MiniHeader.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Subscription',
  components: {
    Header,
    Footer
  },
  data() {
    return {
      loading: false,
      success: false,
      output: null,
      formFields: {
        companyName: "",
        name: "",
        phone: "",
        email: "",
        promoCode: "",
        location: ""
      }
    }
  },
  mounted () {
    this.getLocation();
  },
  methods: {
    getLocation(){
      let obj = this
      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition(obj.showPosition);
      } 
    },
    showPosition(position){
      //alert("Here");
      this.location = position.coords.latitude + 
  ", " + position.coords.longitude;
    },
    formData(/*e*/) {
      /*
      let obj = this
      obj.loading = true
      obj.output = null
       const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
      axios({
          method: 'post',
          url: backendUrl+'home/subscription',
          params: this.formFields,
          headers: headers
      })
      .then(function (response) {
        if(response.data.error == 0){
          obj.success = true;
          obj.output = response.data.messageEn;
          obj.loading = false;
        } else {
          obj.success = false;
          obj.output = response.data.messageEn
          obj.loading = false
        }
        console.log(response);
        
      })
      .catch(function (error) {
          console.log(error);
          obj.loading = false;
          obj.output = "oPPs: "+error.message;
      });
      e.preventDefault()
      */
    }

  }

}
</script>
<style scoped>
.form-group{
  padding-bottom: 20px;
}
</style>