<template>
  <div>
    <div class="div_bg">
      &nbsp;
    </div>
    <header>
      <nav class="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
        <div class="container-fluid">
          <a class="navbar-brand" href="#"><img class="logo" src="@/assets/EN.svg" /> </a>
          <button v-on:click="toggleMenu"  class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
            <img class="icon" src="@/assets/svg/list.svg" />
          </button>
          <div class="collapse navbar-collapse" :class="{show:showMenu}" id="navbarCollapse">
            <div class="me-auto"></div>
            
            <button class="act_btn_top">Try Audit for Free</button> &nbsp; &nbsp; 
            <button class="act_btn_top">Sign In</button>
            <span class="lang" @click="changeLocale">{{ locale }}</span>
            
          </div>
        </div>
        <div class="lower_link collapse navbar-collapse" :class="{show:showMenu}">
          <div class="me-auto"></div>
          <ul class="navbar-nav">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">Home</a>
              </li>
              
              <li class="nav-item">
                <router-link to="" @click="goTo('services')"  class="nav-link" >
                  Services
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('features')"  class="nav-link" >
                  Features
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('pricing')"  class="nav-link" >
                  Pricing
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('business')"  class="nav-link" >
                  Business Fields
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('customers')"  class="nav-link" >
                  Customers
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('lessons')"  class="nav-link" >
                  Lessons
                </router-link>
              </li>
              <li class="nav-item">
                <router-link to="" @click="goTo('faq')"  class="nav-link" >
                  FAQ
                </router-link>
              </li>
              
            </ul>
        </div>
      </nav>

    </header>
    
    
  </div>
</template>

<script>
export default {
  name: 'MiniHeader',
  data() {
    return {
      showMenu:false,
      locale: 'En'
    }
  },
   methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    changeLocale(){
      let obj = this;
      const html = document.documentElement
      if(this.locale == 'En'){
        this.locale = 'Ar'
        html.setAttribute('lang', 'ar');
        html.setAttribute('dir', 'rtl');
        obj.$store.commit("setLang", 'ar');
      } else {
        this.locale = 'En'
        html.setAttribute('lang', 'en');
        html.setAttribute('dir', '');
        obj.$store.commit("setLang", 'en');
      }
    },
    goTo(e){
      var elementTo = document.getElementById(e);
      elementTo.scrollIntoView({ block: 'center',  behavior: 'smooth' });
    }
  }
}
</script>

<style scoped>
header{
  position: relative;
  z-index: 1;
}
nav{
  display: block;
}
.navbar{
  opacity: 0.9;
  /*background:#dceaee !important;*/
}
.navbar-nav{
  display: flex;
}
.lower_link{
  display: block;
}
.nav-item{
  padding: 0 1em;
}
.nav-link{
  text-transform: uppercase;
  font-weight: bold;
  color:#fff;
  font-size: 12px;
}
.nav-link:hover{
  color:#eee;
}

.act_btn_top{
  border:1px;
  border-radius: 1em;
  padding:0.5em 1em;
  background: #fff;
  color:#2d398f;
}
.lang{
  border:solid 1px #eee;
  margin: 0 0.5em;
  border-radius: 1em;
  padding:0.2em 0.5em;
  color:#fff;
  cursor: pointer;
}

.div_bg {
  height: 200px;
}
.div_bg:lang(ar){
  text-align: left;
}

.logo{
    height: 56px;
}

.icon{
    height: 28px;
    filter: invert(100%) sepia(100%) saturate(100%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

@media(max-width:744px) {
    /*.navbar .navbar-nav>.nav-item {
        float: none;
        margin-left: .1rem;
    }*/
    .container-fluid{
      padding: 0;
    }
    
    .navbar .collapse{
      background: rgba(120, 123, 89, .6);
      padding: 0 0 1em 1em ;
    }
}

</style>
